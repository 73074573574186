import React, { useEffect, useState } from 'react'
import tour_package_bg from "./assets/images/tour_package_bg.png";
import axios from 'axios';

const AddOns = () => {

    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const [sectionTitle, setSectionTitle] = useState('');
    const [subTitle, setSubTitle] = useState('');
    const [ratingIcon, setRatingIcon] = useState('');
    const [packagesData, setPackagesData] = useState([]);

    const getPackagesData = () => {
        let config = {
            method: 'get',
            url: `${baseUrl}/pugmark/v1/app/add-ons`,
            headers: {
                'Content-Type': 'application/json',
            },
        };

        axios.request(config)
            .then((response) => {
                const { status, data } = response.data;
                if (status) {
                    const { name, rating_icon, addon, subTitle } = data;
                    setSectionTitle(name);
                    setRatingIcon(rating_icon)
                    setPackagesData(addon);
                    setSubTitle(subTitle);
                }
            })
            .catch((error) => {
                console.log(error?.message);
            });
    }

    useEffect(() => {
        getPackagesData();
    }, []);

    return (
        <>
            <section id='tourSection' className="tours-section pt-100 pb-70">
                <div className="pattern-layer" style={{ backgroundImage: `url(${tour_package_bg})` }}></div>
                <div className="auto-container">
                    <div className="tabs-box">
                        <div className="upper-box mb-60 text-center">
                            <div className="sec-title flex-fill">
                                <span className="sub-title">{subTitle}</span>
                                <h2>{sectionTitle}</h2>
                            </div>
                        </div>
                        <div className="tabs-content">
                            <div className="tab active-tab">
                                <div className="row clearfix">
                                    <div className="col-lg-5 col-md-6 col-sm-12 tours-block mx-auto">
                                        <ul>
                                            {packagesData.map((item, index) => (
                                                <li key={index} className='d-flex py-2'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="#007bff" d="M12 22q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2q.85 0 1.65.125t1.575.4q.35.125.563.438T16 3.65v1.425q0 .8.563 1.363T17.925 7H18v.575q0 .575.425 1t1 .425H20.7q.375 0 .675.225t.4.6q.125.525.175 1.063T22 12q0 2.075-.788 3.9t-2.137 3.175t-3.175 2.138T12 22m0-4.5q1.45 0 2.675-.7t1.975-1.9q.15-.3-.025-.6T16.1 14H7.9q-.35 0-.525.3t-.025.6q.75 1.2 1.988 1.9t2.662.7M8.5 11q.625 0 1.063-.437T10 9.5t-.437-1.062T8.5 8t-1.062.438T7 9.5t.438 1.063T8.5 11m7 0q.625 0 1.063-.437T17 9.5t-.437-1.062T15.5 8t-1.062.438T14 9.5t.438 1.063T15.5 11M20 5h-1q-.425 0-.712-.288T18 4t.288-.712T19 3h1V2q0-.425.288-.712T21 1t.713.288T22 2v1h1q.425 0 .713.288T24 4t-.288.713T23 5h-1v1q0 .425-.288.713T21 7t-.712-.288T20 6z" /></svg>
                                                    <p className='mb-0' dangerouslySetInnerHTML={{ __html: item }}></p>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AddOns