import axios from 'axios';
import React, { useEffect, useState } from 'react'

const Servires = () => {

    return (
        <>
            <section id='weAreBest' className="chooseus-section text-center">
                <div className="auto-container">
                    <div className="sec-title mb-50">
                        <span className="sub-title">We are best</span>
                        <h4 className='mb-0 fs-normal'>Top Things to do in Jim Corbett</h4>
                    </div>
                    <div class="row clearfix justify-content-center">
                        <div class="col-lg-4 col-md-6 col-sm-12 chooseus-block">
                            <div class="chooseus-block-one wow fadeInUp animated animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                                <div class="inner-box">
                                    <h3>
                                        <a>Jeep Safari</a>
                                    </h3>
                                    <p>Embark on a thrilling ride through the jungle to spot tigers, elephants, and other wildlife in their natural habitat.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 chooseus-block">
                            <div class="chooseus-block-one wow fadeInUp animated animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                                <div class="inner-box">
                                    <h3>
                                        <a>Elephant Safari</a>
                                    </h3>
                                    <p>Enjoy a unique perspective of the forest while riding an elephant, immersing yourself in nature.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 chooseus-block">
                            <div class="chooseus-block-one wow fadeInUp animated animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                                <div class="inner-box">
                                    <h3>
                                        <a>Corbett Waterfall</a>
                                    </h3>
                                    <p>Visit this serene and picturesque waterfall, perfect for relaxation and nature photography.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 chooseus-block">
                            <div class="chooseus-block-one wow fadeInUp animated animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                                <div class="inner-box">
                                    <h3>
                                        <a>Garjiya Devi Temple</a>
                                    </h3>
                                    <p>A spiritual spot nestled by the river, offering both religious significance and scenic beauty.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 chooseus-block">
                            <div class="chooseus-block-one wow fadeInUp animated animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                                <div class="inner-box">
                                    <h3>
                                        <a>Corbett Museum</a>
                                    </h3>
                                    <p>Learn about the rich history and wildlife of the park through fascinating exhibits and artifacts.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 chooseus-block">
                            <div class="chooseus-block-one wow fadeInUp animated animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                                <div class="inner-box">
                                    <h3>
                                        <a>Bird Watching</a>
                                    </h3>
                                    <p>Spot rare and colorful bird species, making it a haven for bird lovers and photographers.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 chooseus-block">
                            <div class="chooseus-block-one wow fadeInUp animated animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                                <div class="inner-box">
                                    <h3>
                                        <a>Nature Walks</a>
                                    </h3>
                                    <p>Stroll through the park’s lush greenery, exploring its flora and fauna at a slower, peaceful pace</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 chooseus-block">
                            <div class="chooseus-block-one wow fadeInUp animated animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                                <div class="inner-box">
                                    <h3>
                                        <a>Fishing and Angling</a>
                                    </h3>
                                    <p>Enjoy a calm fishing experience along the Ramganga River, perfect for relaxation and solitude.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 chooseus-block">
                            <div class="chooseus-block-one wow fadeInUp animated animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                                <div class="inner-box">
                                    <h3>
                                        <a>Adventure Activities</a>
                                    </h3>
                                    <p>Indulge in ziplining, rock climbing, and other adrenaline-pumping outdoor activities at nearby adventure camps.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 chooseus-block">
                            <div class="chooseus-block-one wow fadeInUp animated animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                                <div class="inner-box">
                                    <h3>
                                        <a>Stay at a Wildlife Resort</a>
                                    </h3>
                                    <p>Relax at a luxurious resort in the middle of the wilderness, enjoying bonfires, live music, and recreational activities.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Servires