import React from 'react'
import ShortHeader from '../ShortHeader'
import Navigation from '../Navigation'
import BannerSlider from '../BannerSlider'
import AboutUs from '../AboutUs'
import WeAreBest from '../WeAreBest'
// import TopPlaces from '../TopPlaces'
import TourType from '../TourType'
import Gallary from '../Gallary'
import TourPackages from '../TourPackages'
import Reviews from '../Reviews'
import Footer from '../Footer'
import JimCorbettBanner from '../JimCorbettBanner'
import PackagesCategories from '../PackagesCategories'
import AddOns from '../AddOns'

const PugmarkHoliday = () => {
  return (
    <>
      <div className='sticky-top'>
        <ShortHeader />
        <Navigation />
      </div>
      <BannerSlider />
      <AboutUs />
      <WeAreBest />
      <JimCorbettBanner />
      <TourPackages />
      <PackagesCategories />
      <AddOns />
      {/* <TopPlaces /> */}
      <Gallary />
      <TourType />
      <Reviews />
      <Footer />
    </>
  )
}

export default PugmarkHoliday