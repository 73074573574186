import React from 'react'
import jcb from "./assets/images/jim_carbett_img.png";
import { Link } from 'react-router-dom';

const JimCorbettBanner = () => {
    return (
        <section id='jimCorbettSection' className='pb-90' style={{ color: "var(--bs-gray-200)" }}>
            <div className='auto-container'>
                <div className='row'>
                    <div className='col d-flex align-items-center'>
                        <div className="sec-title">
                            <h4 className='mb-4'>Welcome to</h4>
                            <h1 className='mb-5' style={{ fontSize: "6rem", lineHeight: "6rem" }}>Jim Corbett</h1>
                            <Link to="/jim-corbett" className="ast-custom-button px-5 py-3" style={{ fontSize: "1.25rem" }}>Let’s GO</Link>
                        </div>
                    </div>
                    <div className='col-auto'>
                        <img className='img-fluid' src={jcb} alt='Jim Corbett Banner' />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default JimCorbettBanner