import React, { useState } from 'react'
import '../assets/css/jim-corbett/frontend.min.css';
import '../assets/css/jim-corbett/style-main.css';
import '../assets/css/jim-corbett/main.min.css';
import '../assets/css/jim-corbett/post-5.css';
import '../assets/css/jim-corbett/post-6.css';
import '../assets/css/jim-corbett/custom.css';
import logo from "../assets/images/logo.png"
import JimCorbetTemplateHero from "../assets/images/jim-carbett/vlogger-template-hero-img.png"
import vloggerTemplateHeroImgMobile from "../assets/images/jim-carbett/vlogger-template-hero-img-mobile.png"
import Servires from '../Servires';
import PackagesCategories from '../PackagesCategories';
import AddOns from '../AddOns';
import Footer from '../Footer';
import { Link } from 'react-router-dom';

const JimCorbet = () => {
    const [scrollClass, setScrollClass] = useState("");
    const [classProVar, setClassProVar] = useState("");
    document.querySelector('body').classList.add("ast-theme-transparent-header");
    document.querySelector('body').classList.add("ast-page-builder-template");
    document.querySelector('body').classList.add("ast-single-post");
    window.addEventListener('scroll', () => {
        let activeClass = 'bg-white';
        let classVar = '#212529';
        if (window.scrollY === 0) {
            activeClass = '';
            classVar = '';
        }
        setScrollClass(activeClass);
        setClassProVar(classVar);
    });
    return (
        <>
            <div className="hfeed site" id="page">
                <header className={`fixed-top ${scrollClass}`} style={{ "--ast-global-color-5": classProVar, "--ast-global-color-4": classProVar }}>
                    <div id="ast-desktop-header" data-toggle-type="dropdown">
                        <div className="ast-main-header-wrap main-header-bar-wrap">
                            <div className="ast-primary-header-bar ast-primary-header main-header-bar site-header-focus-item" data-section="section-primary-header-builder">
                                <div className="site-primary-header-wrap ast-builder-grid-row-container site-header-focus-item ast-container" data-section="section-primary-header-builder">
                                    <div className="ast-builder-grid-row ast-builder-grid-row-has-sides ast-builder-grid-row-no-center">
                                        <div className="site-header-primary-section-left site-header-section ast-flex site-header-section-left">
                                            <div className="ast-builder-layout-element ast-flex site-header-focus-item" data-section="title_tagline">
                                                <div className="site-branding ast-site-identity" itemType="" itemScope="itemScope">
                                                    <span className="site-logo-img">
                                                        <a href="/#/" className="custom-logo-link" rel="home" aria-current="page">
                                                            <img width="100" src={logo} className="custom-logo" alt="" decoding="async" />
                                                        </a>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="site-header-primary-section-right site-header-section ast-flex ast-grid-right-section">
                                            <div className="ast-builder-menu-1 ast-builder-menu ast-flex ast-builder-menu-1-focus-item ast-builder-layout-element site-header-focus-item" data-section="section-hb-menu-1">
                                                <div className="ast-main-header-bar-alignment">
                                                    <div className="main-header-bar-navigation">
                                                        <nav className="site-navigation ast-flex-grow-1 navigation-accessibility site-header-focus-item" id="primary-site-navigation-desktop" aria-label="Site Navigation: Primary Menu" itemType="" itemScope="itemScope">
                                                            <div className="main-navigation ast-inline-flex">
                                                                <ul id="ast-hf-menu-1" className="main-header-menu ast-menu-shadow ast-nav-menu ast-flex submenu-with-border astra-menu-animation-fade stack-on-mobile">
                                                                    <li id="menu-item-528" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-6 current_page_item menu-item-528">
                                                                        <Link to="/" className="menu-link">Home</Link>
                                                                    </li>
                                                                    <li id="menu-item-529" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-529">
                                                                        <Link to="#about-me" className="menu-link">About Me</Link>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </nav>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="ast-mobile-header" className="ast-mobile-header-wrap" data-type="dropdown">
                        <div className="ast-main-header-wrap main-header-bar-wrap">
                            <div className="ast-primary-header-bar ast-primary-header main-header-bar site-primary-header-wrap site-header-focus-item ast-builder-grid-row-layout-default ast-builder-grid-row-tablet-layout-default ast-builder-grid-row-mobile-layout-default" data-section="section-primary-header-builder">
                                <div className="ast-builder-grid-row ast-builder-grid-row-has-sides ast-builder-grid-row-no-center">
                                    <div className="site-header-primary-section-left site-header-section ast-flex site-header-section-left">
                                        <div className="ast-builder-layout-element ast-flex site-header-focus-item" data-section="title_tagline">
                                            <div className="site-branding ast-site-identity" itemType="" itemScope="itemScope">
                                                <span className="site-logo-img">
                                                    <a href="/#/" className="custom-logo-link" rel="home" aria-current="page">
                                                        <img width="43" height="57" src={logo} className="custom-logo" alt="" decoding="async" />
                                                    </a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="site-header-primary-section-right site-header-section ast-flex ast-grid-right-section">
                                        <div className="ast-builder-layout-element ast-flex site-header-focus-item" data-section="section-header-mobile-trigger">
                                            <div className="ast-button-wrap">
                                                <button type="button" className="menu-toggle main-header-menu-toggle ast-mobile-menu-trigger-fill" aria-expanded="false" data-index="0">
                                                    <span className="screen-reader-text">Main Menu</span>
                                                    <span className="mobile-menu-toggle-icon">
                                                        <span className="ahfb-svg-iconset ast-inline-flex svg-baseline">
                                                            <svg className="ast-mobile-svg ast-menu-svg" fill="currentColor" version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                                <path d="M3 13h18c0.552 0 1-0.448 1-1s-0.448-1-1-1h-18c-0.552 0-1 0.448-1 1s0.448 1 1 1zM3 7h18c0.552 0 1-0.448 1-1s-0.448-1-1-1h-18c-0.552 0-1 0.448-1 1s0.448 1 1 1zM3 19h18c0.552 0 1-0.448 1-1s-0.448-1-1-1h-18c-0.552 0-1 0.448-1 1s0.448 1 1 1z"></path>
                                                            </svg>
                                                        </span>
                                                        <span className="ahfb-svg-iconset ast-inline-flex svg-baseline">
                                                            <svg className="ast-mobile-svg ast-close-svg" fill="currentColor" version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                                <path d="M5.293 6.707l5.293 5.293-5.293 5.293c-0.391 0.391-0.391 1.024 0 1.414s1.024 0.391 1.414 0l5.293-5.293 5.293 5.293c0.391 0.391 1.024 0.391 1.414 0s0.391-1.024 0-1.414l-5.293-5.293 5.293-5.293c0.391-0.391 0.391-1.024 0-1.414s-1.024-0.391-1.414 0l-5.293 5.293-5.293-5.293c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414z"></path>
                                                            </svg>
                                                        </span>
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ast-mobile-header-content content-align-flex-start show">
                            <div className="ast-builder-menu-1 ast-builder-menu ast-flex ast-builder-menu-1-focus-item ast-builder-layout-element site-header-focus-item" data-section="section-hb-menu-1">
                                <div className="ast-main-header-bar-alignment">
                                    <div className="main-header-bar-navigation">
                                        <nav className="site-navigation ast-flex-grow-1 navigation-accessibility site-header-focus-item" id="primary-site-navigation-mobile" aria-label="Site Navigation: Primary Menu" itemType="" itemScope="itemScope">
                                            <div className="main-navigation ast-inline-flex">
                                                <ul id="ast-hf-menu-1" className="main-header-menu ast-menu-shadow ast-nav-menu ast-flex submenu-with-border astra-menu-animation-fade stack-on-mobile">
                                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-6 current_page_item menu-item-528">
                                                        <a href="/#/" aria-current="page" className="menu-link">Home</a>
                                                    </li>
                                                    <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-529">
                                                        <a href="/#/" className="menu-link">About Me</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div id="content" className="site-content">
                    <div className="ast-container">
                        <div id="primary" className="content-area primary">
                            <main id="main" className="site-main">
                                <article className="post-6 page type-page status-publish ast-article-single" id="post-6" itemType="" itemScope="itemScope">
                                    <header className="entry-header ast-no-title ast-header-without-markup"></header>
                                    <div className="entry-content clear" itemProp="text">
                                        <div data-elementor-type="wp-page" data-elementor-id="6" className="elementor elementor-6">
                                            <div className="elementor-element elementor-element-5ad210e e-con-full e-flex e-con e-parent e-lazyloaded" data-id="5ad210e" data-element_type="container" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div className="elementor-element elementor-element-552b94c e-con-full e-flex e-con e-child" data-id="552b94c" data-element_type="container">
                                                    <div className="elementor-element elementor-element-0e106c0 e-flex e-con-boxed e-con e-child" data-id="0e106c0" data-element_type="container">
                                                        <div className="e-con-inner">
                                                            <div className="elementor-element elementor-element-66315a7 e-con-full e-flex e-con e-child" data-id="66315a7" data-element_type="container">
                                                                <div className="elementor-element elementor-element-9c98d5e elementor-widget elementor-widget-heading" data-id="9c98d5e" data-element_type="widget" data-widget_type="heading.default">
                                                                    <div className="elementor-widget-container">
                                                                        <h1 className="elementor-heading-title elementor-size-default">Jim Corbett</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="elementor-element elementor-element-f2a9eca e-con-full e-flex e-con e-child" data-id="f2a9eca" data-element_type="container">
                                                                <div className="elementor-element elementor-element-6f23534 elementor-widget-divider--view-line elementor-widget elementor-widget-divider" data-id="6f23534" data-element_type="widget" data-widget_type="divider.default">
                                                                    <div className="elementor-widget-container">
                                                                        <div className="elementor-divider">
                                                                            <span className="elementor-divider-separator">
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="elementor-element elementor-element-593a398 elementor-widget elementor-widget-heading" data-id="593a398" data-element_type="widget" data-widget_type="heading.default">
                                                                    <div className="elementor-widget-container">
                                                                        <h4 className="elementor-heading-title elementor-size-default">
                                                                            Pugmark<br />Holidays
                                                                        </h4>
                                                                    </div>
                                                                </div>
                                                                <div className="elementor-element elementor-element-76f2268 elementor-widget elementor-widget-text-editor" data-id="76f2268" data-element_type="widget" data-widget_type="text-editor.default">
                                                                    <div className="elementor-widget-container">
                                                                        <p>
                                                                            Varius suscipit nibh vitae arcu, magna id
                                                                            semper nibh ultricies est nulla pulvinar
                                                                            quisque ultrices lacus, et gravida tincidunt
                                                                            elit dolor eu bibendum non.
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="elementor-element elementor-element-eb536f8 elementor-absolute elementor-hidden-phone elementor-widget elementor-widget-image" data-id="eb536f8" data-element_type="widget" data-settings="{&quot;_position&quot;:&quot;absolute&quot;}" data-widget_type="image.default">
                                                        <div className="elementor-widget-container">
                                                            <img fetchpriority="high" decoding="async" width="1920" height="690" src={JimCorbetTemplateHero} className="attachment-full size-full wp-image-41" alt="" sizes="(max-width: 1920px) 100vw, 1920px" />
                                                        </div>
                                                    </div>
                                                    <div className="elementor-element elementor-element-d09c229 elementor-hidden-desktop elementor-hidden-tablet elementor-widget elementor-widget-image" data-id="d09c229" data-element_type="widget" data-widget_type="image.default">
                                                        <div className="elementor-widget-container">
                                                            <img decoding="async" width="400" height="308" src={vloggerTemplateHeroImgMobile} className="attachment-full size-full wp-image-322" alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="elementor-element elementor-element-765236b e-flex e-con-boxed e-con e-parent e-lazyloaded" data-id="765236b" data-element_type="container">
                                                <div className="e-con-inner">
                                                    <div className="elementor-element elementor-element-f64802a e-con-full e-flex e-con e-child" data-id="f64802a" data-element_type="container">
                                                        <div className="elementor-element elementor-element-55d659a elementor-widget elementor-widget-heading" data-id="55d659a" data-element_type="widget" data-widget_type="heading.default">
                                                            <div className="elementor-widget-container">
                                                                <h6 className="elementor-heading-title elementor-size-default">
                                                                    What You’ll Find Here -  Unforgettable adventures in the heart of nature.
                                                                </h6>
                                                            </div>
                                                        </div>
                                                        <div className="elementor-element elementor-element-f06be0a e-con-full e-flex e-con e-child" data-id="f06be0a" data-element_type="container">
                                                            <div className="elementor-element elementor-element-ccccb49 e-con-full e-flex e-con e-child" data-id="ccccb49" data-element_type="container">
                                                                <div className="elementor-element elementor-element-6470d42 elementor-widget elementor-widget-heading" data-id="6470d42" data-element_type="widget" data-widget_type="heading.default">
                                                                    <div className="elementor-widget-container">
                                                                        <h3 className="elementor-heading-title elementor-size-default">
                                                                            Adventure
                                                                        </h3>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="elementor-element elementor-element-2a3ee10 e-con-full e-flex e-con e-child" data-id="2a3ee10" data-element_type="container">
                                                                <div className="elementor-element elementor-element-6d5b8e4 elementor-widget elementor-widget-heading" data-id="6d5b8e4" data-element_type="widget" data-widget_type="heading.default">
                                                                    <div className="elementor-widget-container">
                                                                        <h3 className="elementor-heading-title elementor-size-default">
                                                                            Wild
                                                                        </h3>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="elementor-element elementor-element-53220e2 e-con-full e-flex e-con e-child" data-id="53220e2" data-element_type="container">
                                                                <div className="elementor-element elementor-element-e91151a elementor-widget elementor-widget-heading" data-id="e91151a" data-element_type="widget" data-widget_type="heading.default">
                                                                    <div className="elementor-widget-container">
                                                                        <h3 className="elementor-heading-title elementor-size-default">
                                                                            Outdoor
                                                                        </h3>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="elementor-element elementor-element-54410dd e-con-full e-flex e-con e-child" data-id="54410dd" data-element_type="container">
                                                                <div className="elementor-element elementor-element-2b1e512 elementor-widget elementor-widget-heading" data-id="2b1e512" data-element_type="widget" data-widget_type="heading.default">
                                                                    <div className="elementor-widget-container">
                                                                        <h3 className="elementor-heading-title elementor-size-default">
                                                                            Extreme
                                                                        </h3>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="elementor-element elementor-element-2ba220a e-con-full e-flex e-con e-child" data-id="2ba220a" data-element_type="container">
                                                            <div className="elementor-element elementor-element-db96d1b e-con-full e-flex e-con e-child" data-id="db96d1b" data-element_type="container">
                                                                <div className="elementor-element elementor-element-de807c5 elementor-widget elementor-widget-heading" data-id="de807c5" data-element_type="widget" data-widget_type="heading.default">
                                                                    <div className="elementor-widget-container">
                                                                        <h3 className="elementor-heading-title elementor-size-default">
                                                                            Waterfall
                                                                        </h3>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="elementor-element elementor-element-d5ef990 e-con-full e-flex e-con e-child" data-id="d5ef990" data-element_type="container">
                                                                <div className="elementor-element elementor-element-e013d0e elementor-widget elementor-widget-heading" data-id="e013d0e" data-element_type="widget" data-widget_type="heading.default">
                                                                    <div className="elementor-widget-container">
                                                                        <h3 className="elementor-heading-title elementor-size-default">
                                                                            Nature-bound
                                                                        </h3>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="elementor-element elementor-element-255a20b e-flex e-con-boxed e-con e-parent e-lazyloaded" data-id="255a20b" data-element_type="container" id="about-me" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div className="e-con-inner">
                                                    <div className="elementor-element elementor-element-5a83fd5 e-con-full e-flex e-con e-child" data-id="5a83fd5" data-element_type="container">
                                                        <div className="elementor-element elementor-element-73b4b01 elementor-widget elementor-widget-heading" data-id="73b4b01" data-element_type="widget" data-widget_type="heading.default">
                                                            <div className="elementor-widget-container">
                                                                <h2 className="elementor-heading-title elementor-size-default">About</h2>
                                                            </div>
                                                        </div>
                                                        <div className="elementor-element elementor-element-8a77e54 e-con-full e-flex e-con e-child mt-0" data-id="8a77e54" data-element_type="container">
                                                            <div className="elementor-element elementor-element-8b4adce e-con-full e-flex e-con e-child pe-0" data-id="8b4adce" data-element_type="container">
                                                                <div className="elementor-element elementor-element-456aac8 elementor-widget-divider--view-line elementor-widget elementor-widget-divider" data-id="456aac8" data-element_type="widget" data-widget_type="divider.default">
                                                                    <div className="elementor-widget-container">
                                                                        <div className="elementor-divider">
                                                                            <span className="elementor-divider-separator">
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="elementor-element elementor-element-5afef92 elementor-widget elementor-widget-text-editor" data-id="5afef92" data-element_type="widget" data-widget_type="text-editor.default">
                                                                    <div className="elementor-widget-container">
                                                                        <p style={{ fontSize: "1.2rem" }}>Welcome to an extraordinary Jim Corbett experience, where adventure meets comfort at affordable rates! Whether you're yearning for the thrill of a jungle safari, the serenity of a nature walk, or the relaxation of a luxury resort, our personalized packages are designed to make your trip unforgettable. With handpicked stays, curated itineraries, and no hidden costs, we ensure every traveler enjoys a seamless and enriching experience. Discover Jim Corbett in a way that suits your style, budget, and adventure spirit—all while keeping your comfort and safety our top priority.</p>
                                                                    </div>
                                                                </div>
                                                                <div className="elementor-element elementor-element-ead0a1e elementor-widget elementor-widget-text-editor" data-id="ead0a1e" data-element_type="widget" data-widget_type="text-editor.default">
                                                                    <div className="elementor-widget-container">
                                                                        <p style={{ fontSize: "1.2rem" }}>
                                                                            <svg className='me-3' xmlns="http://www.w3.org/2000/svg" width="1.3em" height="1.3em" viewBox="0 0 16 16"><path fill="#fe8828" d="M8.834.066c.763.087 1.5.295 2.01.884c.505.581.656 1.378.656 2.3c0 .467-.087 1.119-.157 1.637L11.328 5h1.422c.603 0 1.174.085 1.668.333c.508.254.911.679 1.137 1.2c.453.998.438 2.447.188 4.316l-.04.306c-.105.79-.195 1.473-.313 2.033c-.131.63-.315 1.209-.668 1.672C13.97 15.847 12.706 16 11 16c-1.848 0-3.234-.333-4.388-.653q-.247-.068-.475-.133c-.658-.186-1.2-.34-1.725-.415A1.75 1.75 0 0 1 2.75 16h-1A1.75 1.75 0 0 1 0 14.25v-7.5C0 5.784.784 5 1.75 5h1a1.75 1.75 0 0 1 1.514.872c.258-.105.59-.268.918-.508C5.853 4.874 6.5 4.079 6.5 2.75v-.5c0-1.202.994-2.337 2.334-2.184M4.5 13.3c.705.088 1.39.284 2.072.478l.441.125c1.096.305 2.334.598 3.987.598c1.794 0 2.28-.223 2.528-.549c.147-.193.276-.505.394-1.07c.105-.502.188-1.124.295-1.93l.04-.3c.25-1.882.189-2.933-.068-3.497a.92.92 0 0 0-.442-.48c-.208-.104-.52-.174-.997-.174H11c-.686 0-1.295-.577-1.206-1.336q.036-.29.076-.586c.065-.488.13-.97.13-1.328c0-.809-.144-1.15-.288-1.316c-.137-.158-.402-.304-1.048-.378C8.357 1.521 8 1.793 8 2.25v.5c0 1.922-.978 3.128-1.933 3.825a5.8 5.8 0 0 1-1.567.81ZM2.75 6.5h-1a.25.25 0 0 0-.25.25v7.5c0 .138.112.25.25.25h1a.25.25 0 0 0 .25-.25v-7.5a.25.25 0 0 0-.25-.25" /></svg>
                                                                            <b>Unleash Your Inner Explorer :</b> Experience the raw beauty of Jim Corbett with thrilling jeep safaris and up-close encounters with wildlife. Perfect for adventurers looking to reconnect with nature.
                                                                        </p>
                                                                        <p style={{ fontSize: "1.2rem" }}>
                                                                            <svg className='me-3' xmlns="http://www.w3.org/2000/svg" width="1.3em" height="1.3em" viewBox="0 0 16 16"><path fill="#fe8828" d="M8.834.066c.763.087 1.5.295 2.01.884c.505.581.656 1.378.656 2.3c0 .467-.087 1.119-.157 1.637L11.328 5h1.422c.603 0 1.174.085 1.668.333c.508.254.911.679 1.137 1.2c.453.998.438 2.447.188 4.316l-.04.306c-.105.79-.195 1.473-.313 2.033c-.131.63-.315 1.209-.668 1.672C13.97 15.847 12.706 16 11 16c-1.848 0-3.234-.333-4.388-.653q-.247-.068-.475-.133c-.658-.186-1.2-.34-1.725-.415A1.75 1.75 0 0 1 2.75 16h-1A1.75 1.75 0 0 1 0 14.25v-7.5C0 5.784.784 5 1.75 5h1a1.75 1.75 0 0 1 1.514.872c.258-.105.59-.268.918-.508C5.853 4.874 6.5 4.079 6.5 2.75v-.5c0-1.202.994-2.337 2.334-2.184M4.5 13.3c.705.088 1.39.284 2.072.478l.441.125c1.096.305 2.334.598 3.987.598c1.794 0 2.28-.223 2.528-.549c.147-.193.276-.505.394-1.07c.105-.502.188-1.124.295-1.93l.04-.3c.25-1.882.189-2.933-.068-3.497a.92.92 0 0 0-.442-.48c-.208-.104-.52-.174-.997-.174H11c-.686 0-1.295-.577-1.206-1.336q.036-.29.076-.586c.065-.488.13-.97.13-1.328c0-.809-.144-1.15-.288-1.316c-.137-.158-.402-.304-1.048-.378C8.357 1.521 8 1.793 8 2.25v.5c0 1.922-.978 3.128-1.933 3.825a5.8 5.8 0 0 1-1.567.81ZM2.75 6.5h-1a.25.25 0 0 0-.25.25v7.5c0 .138.112.25.25.25h1a.25.25 0 0 0 .25-.25v-7.5a.25.25 0 0 0-.25-.25" /></svg>
                                                                            <b>Safety First, Always :</b> Relax and explore with peace of mind. From safari trails to resort stays, your safety is our priority, ensuring you can enjoy the journey stress-free.
                                                                        </p>
                                                                        <p style={{ fontSize: "1.2rem" }}>
                                                                            <svg className='me-3' xmlns="http://www.w3.org/2000/svg" width="1.3em" height="1.3em" viewBox="0 0 16 16"><path fill="#fe8828" d="M8.834.066c.763.087 1.5.295 2.01.884c.505.581.656 1.378.656 2.3c0 .467-.087 1.119-.157 1.637L11.328 5h1.422c.603 0 1.174.085 1.668.333c.508.254.911.679 1.137 1.2c.453.998.438 2.447.188 4.316l-.04.306c-.105.79-.195 1.473-.313 2.033c-.131.63-.315 1.209-.668 1.672C13.97 15.847 12.706 16 11 16c-1.848 0-3.234-.333-4.388-.653q-.247-.068-.475-.133c-.658-.186-1.2-.34-1.725-.415A1.75 1.75 0 0 1 2.75 16h-1A1.75 1.75 0 0 1 0 14.25v-7.5C0 5.784.784 5 1.75 5h1a1.75 1.75 0 0 1 1.514.872c.258-.105.59-.268.918-.508C5.853 4.874 6.5 4.079 6.5 2.75v-.5c0-1.202.994-2.337 2.334-2.184M4.5 13.3c.705.088 1.39.284 2.072.478l.441.125c1.096.305 2.334.598 3.987.598c1.794 0 2.28-.223 2.528-.549c.147-.193.276-.505.394-1.07c.105-.502.188-1.124.295-1.93l.04-.3c.25-1.882.189-2.933-.068-3.497a.92.92 0 0 0-.442-.48c-.208-.104-.52-.174-.997-.174H11c-.686 0-1.295-.577-1.206-1.336q.036-.29.076-.586c.065-.488.13-.97.13-1.328c0-.809-.144-1.15-.288-1.316c-.137-.158-.402-.304-1.048-.378C8.357 1.521 8 1.793 8 2.25v.5c0 1.922-.978 3.128-1.933 3.825a5.8 5.8 0 0 1-1.567.81ZM2.75 6.5h-1a.25.25 0 0 0-.25.25v7.5c0 .138.112.25.25.25h1a.25.25 0 0 0 .25-.25v-7.5a.25.25 0 0 0-.25-.25" /></svg>
                                                                            <b>Affordable Travel, Unmatched Experiences :</b> Who says adventure has to be expensive? Our budget-friendly packages offer luxurious stays and unforgettable experiences, all at unbeatable prices.
                                                                        </p>
                                                                        <p style={{ fontSize: "1.2rem" }}>
                                                                            <svg className='me-3' xmlns="http://www.w3.org/2000/svg" width="1.3em" height="1.3em" viewBox="0 0 16 16"><path fill="#fe8828" d="M8.834.066c.763.087 1.5.295 2.01.884c.505.581.656 1.378.656 2.3c0 .467-.087 1.119-.157 1.637L11.328 5h1.422c.603 0 1.174.085 1.668.333c.508.254.911.679 1.137 1.2c.453.998.438 2.447.188 4.316l-.04.306c-.105.79-.195 1.473-.313 2.033c-.131.63-.315 1.209-.668 1.672C13.97 15.847 12.706 16 11 16c-1.848 0-3.234-.333-4.388-.653q-.247-.068-.475-.133c-.658-.186-1.2-.34-1.725-.415A1.75 1.75 0 0 1 2.75 16h-1A1.75 1.75 0 0 1 0 14.25v-7.5C0 5.784.784 5 1.75 5h1a1.75 1.75 0 0 1 1.514.872c.258-.105.59-.268.918-.508C5.853 4.874 6.5 4.079 6.5 2.75v-.5c0-1.202.994-2.337 2.334-2.184M4.5 13.3c.705.088 1.39.284 2.072.478l.441.125c1.096.305 2.334.598 3.987.598c1.794 0 2.28-.223 2.528-.549c.147-.193.276-.505.394-1.07c.105-.502.188-1.124.295-1.93l.04-.3c.25-1.882.189-2.933-.068-3.497a.92.92 0 0 0-.442-.48c-.208-.104-.52-.174-.997-.174H11c-.686 0-1.295-.577-1.206-1.336q.036-.29.076-.586c.065-.488.13-.97.13-1.328c0-.809-.144-1.15-.288-1.316c-.137-.158-.402-.304-1.048-.378C8.357 1.521 8 1.793 8 2.25v.5c0 1.922-.978 3.128-1.933 3.825a5.8 5.8 0 0 1-1.567.81ZM2.75 6.5h-1a.25.25 0 0 0-.25.25v7.5c0 .138.112.25.25.25h1a.25.25 0 0 0 .25-.25v-7.5a.25.25 0 0 0-.25-.25" /></svg>
                                                                            <b>Trusted Travel Experts at Your Service :</b> With years of local expertise, we guide you through the best spots, hidden gems, and must-see sights, ensuring a seamless and enriching journey.
                                                                        </p>
                                                                        <p style={{ fontSize: "1.2rem" }}>Explore the wonders of Jim Corbett with us—where adventure, nature, and affordability come together for the perfect getaway!</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <PackagesCategories />
                                            <AddOns />
                                            <div className="elementor-element elementor-element-b1b48fe e-flex e-con-boxed e-con e-parent e-lazyloaded" data-id="b1b48fe" data-element_type="container" id="vlog" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}" style={{ backgroundColor: "#fff" }}>
                                                <div className="e-con-inner">
                                                    <div className="elementor-element elementor-element-8a77e54 e-con-full e-flex e-con e-child mt-0" data-id="8a77e54" data-element_type="container">
                                                        <div className="elementor-element elementor-element-8b4adce e-con-full e-flex e-con e-child pe-0" data-id="8b4adce" data-element_type="container">
                                                            <div className="elementor-element elementor-element-ead0a1e elementor-widget elementor-widget-text-editor" data-id="ead0a1e" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div className="elementor-widget-container">
                                                                    <Servires />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </article>
                            </main>
                        </div>
                    </div>
                </div>
                <Footer />
            </div >
        </>
    )
}

export default JimCorbet