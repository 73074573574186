import React from 'react';
// import { BrowserRouter, Routes, Route, useRoutes } from "react-router-dom";
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';

import PugmarkHoliday from './pages/PugmarkHoliday';
import JimCorbet from './pages/JimCorbet';

// const AppRoutes = () => {
//   const routes = useRoutes([
//     { path: "/", element: <PugmarkHoliday /> },
//     { path: "/jim-corbett", element: <JimCorbet /> }
//   ]);

//   return routes;
// };

function App() {

  return (
    <>
      {/* <BrowserRouter>
        <AppRoutes />
      </BrowserRouter> */}
      <Router>
        <Routes>
          <Route path="/" element={<PugmarkHoliday />} />
          <Route path="/jim-corbett" element={<JimCorbet />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
