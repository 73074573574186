import React, { useEffect, useState } from 'react'
import tour_package_bg from "./assets/images/tour_package_bg.png";
import axios from 'axios';

const PackagesCategories = () => {

    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const [sectionTitle, setSectionTitle] = useState('');
    const [subTitle, setSubTitle] = useState('');
    const [ratingIcon, setRatingIcon] = useState('');
    const [packagesData, setPackagesData] = useState([]);

    const getPackagesData = () => {
        let config = {
            method: 'get',
            url: `${baseUrl}/pugmark/v1/app/packages-categories`,
            headers: {
                'Content-Type': 'application/json',
            },
        };

        axios.request(config)
            .then((response) => {
                const { status, data } = response.data;
                if (status) {
                    const { name, rating_icon, packages, subTitle } = data;
                    setSectionTitle(name);
                    setRatingIcon(rating_icon)
                    setPackagesData(packages);
                    setSubTitle(subTitle);
                }
            })
            .catch((error) => {
                console.log(error?.message);
            });
    }

    useEffect(() => {
        getPackagesData();
    }, []);

    return (
        <>
            <section id='tourSection' className="tours-section pt-150 pb-70">
                <div className="pattern-layer" style={{ backgroundImage: `url(${tour_package_bg})` }}></div>
                <div className="auto-container">
                    <div className="tabs-box">
                        <div className="upper-box mb-60 text-center">
                            <div className="sec-title flex-fill">
                                <span className="sub-title">{subTitle}</span>
                                <h2>{sectionTitle}</h2>
                            </div>
                        </div>
                        <div className="tabs-content">
                            <div className="tab active-tab">
                                <div className="row clearfix">
                                    {packagesData.map((item, index) => (
                                        <div key={index} className="col-lg-3 col-md-6 col-sm-12 tours-block">
                                            <div className="tours-block-one h-100 d-flex">
                                                <div className="inner-box">
                                                    <div className="image-box">
                                                        <figure className="image mb-0" style={{ height: '12rem' }}>
                                                            <img src={baseUrl + item?.image} alt="" />
                                                        </figure>
                                                        {/* <span className="rating"><img src={baseUrl + ratingIcon} alt="" /> {item?.rating}</span> */}
                                                    </div>
                                                    <div className="lower-content py-3 px-4">
                                                        <h4 style={{ fontSize: "1.1em" }} className='mb-1'>
                                                            <a href={baseUrl + item?.linkUrl} target={item?.linkUrl ? `blank` : ''}>{item?.name}</a>
                                                        </h4>
                                                        {item?.caption ? <p style={{ fontSize: "13px", lineHeight: "1.5" }}>{item?.caption}</p> : ''}
                                                        <ul className='list-group ms-1'>
                                                            {item?.addon.map((listItem, i) => (
                                                                <li key={i} className='py-2'>
                                                                    <svg className='me-2' xmlns="http://www.w3.org/2000/svg" width="1.7em" height="1.7em" viewBox="0 0 512 512">
                                                                        <defs>
                                                                            <linearGradient id="meteoconsStarFill0" x1="187.9" x2="324.1" y1="138.1" y2="373.9" gradientUnits="userSpaceOnUse">
                                                                                <stop offset="0" stopColor="#fcd966" />
                                                                                <stop offset=".5" stopColor="#fcd966" />
                                                                                <stop offset="1" stopColor="#fccd34" />
                                                                            </linearGradient>
                                                                        </defs>
                                                                        <path fill="url(#meteoconsStarFill0)" stroke="#fcd34d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" d="m105.7 263.5l107.5 29.9a7.9 7.9 0 0 1 5.4 5.4l29.9 107.5a7.8 7.8 0 0 0 15 0l29.9-107.5a7.9 7.9 0 0 1 5.4-5.4l107.5-29.9a7.8 7.8 0 0 0 0-15l-107.5-29.9a7.9 7.9 0 0 1-5.4-5.4l-29.9-107.5a7.8 7.8 0 0 0-15 0l-29.9 107.5a7.9 7.9 0 0 1-5.4 5.4l-107.5 29.9a7.8 7.8 0 0 0 0 15Z">
                                                                            <animateTransform additive="sum" attributeName="transform" calcMode="spline" dur="6s" keySplines=".42, 0, .58, 1; .42, 0, .58, 1" repeatCount="indefinite" type="rotate" values="-15 256 256; 15 256 256; -15 256 256" />
                                                                            <animate attributeName="opacity" dur="6s" values="1; .75; 1; .75; 1; .75; 1" />
                                                                        </path>
                                                                    </svg>
                                                                    {listItem}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PackagesCategories